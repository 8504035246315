<template>
  <data-table
    v-model="ratingScale"
    :show-filters="false"
    :actions="ratingScale.actions"
    :permission="permission"
  ></data-table>
</template>

<script>
export default {
  name: 'rating-scales',
  components: {},
  data() {
    return {
      permission: {
        module: 'Einstellungen',
        subModule: 'Einstellungen',
        childModule: 'Allgemein Einstellung',
        operation: 'edit',
        options: ['All', 'Only own personal data'],
      },
      ratingScale: {
        resource: 'performance-evaluation/setting/ratingscale',
        actions: {
          index: null,
          list: ['edit', 'delete'],
          callback: state => {
            this.$bus.$emit('show-modal', {
              show: true,
              title:
                state.type === 'delete'
                  ? 'Bewertungsskala entfernen'
                  : 'Bewertungsskala bearbeiten',
              component: state.type === 'delete' ? 'remove-dialog' : 'new-rating-scale',
              request: state.type,
              message: `Möchten Sie wirklich den Bewertungsskala entfernen?`,
              // message: `Möchten Sie wirklich den Bewertungsskala definieren ${state.payload.name} entfernen?`,
              resource: `/performance-evaluation/setting/ratingscale/${state.payload.id}/`,
              data: state.payload,
              maxWidth: 'w-full md:w-1/3',
              event: 'reload-rating-data-table',
              type: state.type,
            })
          },
        },
        columns: [
          {
            label: 'Bewertung',
            field: ['name'],
            type: 'text',
            headerAlign: 'left',
            width: '20',
            prefix: '',
            postfix: '',
          },
          {
            label: 'Beschreibung',
            field: ['description'],
            headerAlign: 'left',
            width: '50',
            type: 'text',
            prefix: '',
            postfix: '',
          },
          { label: '', field: ['id'], type: 'checkbox', width: '10' },
        ],
        rows: [],
      },
      breadcrumbs: {
        title: [
          { link: '/settings', crumb: 'Allgemeine Einstellungen' },
          { link: '/settings/general', crumb: 'Stammdaten' },
          { crumb: 'Bewertungsskala' },
        ],
        actions: {
          title: 'Bewertungsstufe erfassen',
          component: 'new-rating-scale',
          resource: '/rating-scale',
          maxWidth: 'w-full md:w-1/3',
        },
        permission: {
          module: 'Einstellungen',
          subModule: 'Einstellungen',
          childModule: 'Allgemein Einstellung',
          operation: 'edit',
          options: ['All', 'Only own personal data'],
        },
        pageTitle: 'Bewertungsskala definieren',
      },
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$emit('breadcrumbs', this.breadcrumbs)
    },
  },
}
</script>

<style></style>
